import React, { useState } from "react"
import { Link, navigate } from "gatsby"

import { deleteItem, submitOrder, updateNotes, addItemToOrder } from "api/db"

const OrderTable = ({ user, orderNumber, data, notes }) => {
  let items = Object.values(data)
  let totalCases = items.reduce(function(sum, item) {
    return (sum = sum + item.cases)
  }, 0)
  let totalPrice = items.reduce(function(sum, item) {
    return (sum = sum + parseFloat(item.totalPrice))
  }, 0)

  const [showConfirmation, setShowConfirmation] = useState(false)
  const [itemsArray, setItemsArray] = useState(items)

  function deleteItemFromOrder(itemCode) {
    let path = itemCode.replace(/\s+/g, "-").toLowerCase()
    deleteItem(orderNumber, path)
  }

  function getPath(itemCode) {
    if (itemCode) {
      return `/products/${itemCode.replace(/\s+/g, "-").toLowerCase()}`
    }
  }

  function dashItemCode(itemCode) {
    if (itemCode) {
      return `${itemCode.replace(/\s+/g, "-").toLowerCase()}`
    }
  }

  function setQuantity(item, quantity) {
    if (JSON.parse(localStorage.getItem("uid"))) {
      addItemToOrder(
        JSON.parse(localStorage.getItem("uid")),
        dashItemCode(item.itemCode),
        item.itemCode,
        item.description,
        item.price,
        Math.floor(item.packing),
        Math.floor(quantity)
      )
    }
  }

  return (
    <section>
      <p className="is-size-3 has-text-weight-bold has-text-centered">
        Order #{orderNumber}
      </p>
      <p className="is-size-5 is-uppercase "> Bill To: </p>
      <h1 className="is-size-3 has-text-weight-bold"> {user.companyName}</h1>
      <p className="is-size-5"> {user.companyAddress}</p>
      <p className="is-size-5">
        {" "}
        {user.companyCity}, {user.companyState}{" "}
      </p>
      <p className="is-size-5">
        {" "}
        Phone:{" "}
        {user.companyPhoneNumber
          .replace(/\D+/g, "")
          .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}{" "}
        | Fax:{" "}
        {user.companyFaxNumber
          .replace(/\D+/g, "")
          .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}{" "}
      </p>
      <br />
      <table className="table is-bordered is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>Item Code</th>
            <th>Description</th>
            <th>Packing</th>
            <th>Cases</th>
            <th>Pieces</th>
            <th>Unit Price</th>
            <th>Case Price</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {itemsArray.map((item, index) => {
            let price = (Math.round(item.price * 100) / 100).toFixed(2)
            return (
              <tr key={item.itemCode}>
                <th>
                  {" "}
                  <Link to={getPath(item.itemCode)}>{item.itemCode}</Link>
                </th>
                <th className="product-page-description">{item.description}</th>
                <th>{item.packing}/cs</th>
                <th className="cases-order">
                  <input
                    className="input"
                    type="number"
                    pattern="\d*"
                    min="1"
                    defaultValue={item.cases}
                    onChange={e => {
                      setQuantity(item, e.target.value)
                    }}
                  />
                  <span
                    className="reload"
                    onClick={() =>
                      setInterval(function() {
                        window.location.reload(true)
                      }, 500)
                    }
                  >
                    &#8634;
                  </span>
                </th>
                <th>{item.pieces}</th>
                <th className="has-text-right">{price}</th>
                <th className="has-text-right">{item.totalPrice}</th>
                <th>
                  <button
                    className="button is-danger is-small is-rounded"
                    onClick={() => {
                      let newArr = [...itemsArray]
                      newArr.splice(index, 1)
                      setItemsArray(newArr)

                      deleteItemFromOrder(item.itemCode)
                    }}
                  >
                    Remove
                  </button>
                </th>
              </tr>
            )
          })}
        </tbody>
      </table>
      <small>
        {" "}
        &#42;Prices are subject to change without notice. Please note that some
        items may not available due to high demand.
      </small>

      <br />
      <p className="has-text-weight-bold has-text-right is-size-4">
        Total Cases: {totalCases}{" "}
      </p>
      <p className="has-text-weight-bold has-text-right is-size-4">
        Order Total: ${totalPrice.toLocaleString()}
      </p>

      <br />
      <textarea
        className="input is-medium"
        placeholder="Write any notes for your order here..."
        onChange={e => updateNotes(orderNumber, e.target.value)}
      >
        {notes}
      </textarea>
      <br />
      <br />

      <button
        onClick={() => {
          submitOrder(orderNumber, totalCases, totalPrice, notes)
          setShowConfirmation(true)
        }}
        className="button is-info is-rounded"
      >
        {" "}
        Submit Order{" "}
      </button>
      <br />
      <br />
      {showConfirmation && (
        <article className="message is-success is-order-success">
          <div className="message-header">
            <p>Success</p>
          </div>
          <div className="message-body">
            Your order (Order #{orderNumber}) has been submitted and is being
            processed! We will contact you within one business day. Thank you
            for your business.
          </div>
        </article>
      )}
    </section>
  )
}

export default OrderTable
