import React, { Component } from "react"

import Layout from "components/layout"
import SEO from "components/seo"
import OrderTable from "components/Order/OrderTable"
import Spinner from "react-spinkit"

import { fetchUserDataByUid, retrieveOrders } from "api/db"

// add loading indicators on /order & /account

// if order in progress save the order number to localstorage, then on homepage it requests for that order data,

class Order extends Component {
  constructor(props) {
    super(props)

    this.state = {
      user: {},
      order: {},
      orderNumber: null,
      orderInProgress: false,
      dataLoaded: false,
      notes: "",
    }
  }

  componentDidMount() {
    this.getUserData(JSON.parse(localStorage.getItem("uid")))
    this.getActiveOrder(JSON.parse(localStorage.getItem("uid")))
  }

  getUserData = uid => {
    fetchUserDataByUid(uid).on(
      "value",
      function(snapshot) {
        let data = snapshot.val()
        this.setState({
          user: data,
        })
      }.bind(this)
    )
  }

  getActiveOrder = uid => {
    retrieveOrders(uid).then(snapshot => {
      if (snapshot.val()) {
        let order = snapshot.val()[Object.keys(snapshot.val())]
        if (order.status === "in-progress") {
          let orderNumber = order.orderNumber
          let notes
          if (order.notes) {
            notes = order.notes
            delete order.notes
          } else {
            notes = ""
          }
          delete order.dateUpdated
          delete order.status
          delete order.orderNumber
          delete order.uid
          this.setState({
            order: order,
            orderNumber: orderNumber,
            orderInProgress: true,
            dataLoaded: true,
            notes: notes,
          })
        } else {
          // no order in progress
          this.setState({
            dataLoaded: true,
          })
        }
      } else {
        this.setState({
          dataLoaded: true,
        })
        // no order started
      }
    })
  }

  render() {
    return (
      <Layout>
        <SEO title="Place an Order | Tick Tock Inc." />
        <section className="ticktockinc-container-wide">
          {this.state.dataLoaded ? (
            <div>
              {this.state.orderInProgress ? (
                <OrderTable
                  user={this.state.user}
                  orderNumber={this.state.orderNumber}
                  data={this.state.order}
                  notes={this.state.notes}
                />
              ) : (
                <div class="container is-fullhd">
                  <div class="notification is-size-4 has-text-weight-bold has-text-centered">
                    Start by visiting any product page and add an item to your
                    order.{" "}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="product-page-image">
              <Spinner name="ball-scale-multiple" color="#438cee" />
            </div>
          )}
        </section>
      </Layout>
    )
  }
}

export default Order
